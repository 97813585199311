<script>
import AppMenu from "../menu/AppMenu.vue";

import NextMixinListPage from "@/components/mixins/NextMixinListPage";
import MixinActions from "@/components/mixins/MixinListActions";
import MixinList from "./MixinList";
import * as templateDesktop from "./ExpenseListDraftDesktop.vue?vue&type=template";
import * as templateMobile from "./ExpenseListDraftMobile.vue?vue&type=template";
import ModalShow from "./ModalShow.vue";
let render = templateDesktop.render;
if (window.innerWidth < 960) {
  render = templateMobile.render;
}

export default {
  mixins: [NextMixinListPage, MixinActions, MixinList],
  components: { AppMenu, ModalShow },
  render: render,
  data() {
    return {
      title: "Egresos En Borrador",
      form: {},
      url: "api/v1/expense-draft",
      pathBase: "/pos/expense",
      pathCreate: "/pos/expense/create",
      options: {
        page: 1,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100],
        sortBy: ["expense_date"],
        sortDesc: [true],
      },

      headers: [
        { title: "num", value: "index", sortable: false },
        { title: "expense_date", value: "expense_date", sortable: true },
        { title: "person", value: "person", sortable: false },
        { title: "document_state", value: "document_state", sortable: false },
        { title: "total_value", value: "total_value", sortable: true },
        { title: "_actions", value: "_actions", sortable: false },
      ],
    };
  },
  methods: {
    breadcrumbsInit() {
      this.breadcrumbs = [];
    },
  },
};
</script>
